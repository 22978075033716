import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface TableManagerProps { }
export interface TableManagerState {

}

export default class TableManager extends Component<
  TableManagerProps,
  TableManagerState
  > {

  render() {
    return (
      <TileHolder>
        <Tile type="table" title="Table Header Management">
          <TablePage isBase={false} table="TableHeader"></TablePage>
          </Tile>
      </TileHolder>
    );
  }
}
