import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface ApplicationSettingsManagerProps { }

export interface ApplicationSettingsManagerState {

}

export default class ApplicationSettingsManager extends Component<
	ApplicationSettingsManagerProps,
	ApplicationSettingsManagerState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage ApplicationSettings">
					<TablePage isBase={false} table="ApplicationSettings"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
