import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

export type route = {
  isBase?: boolean;
  exact?: boolean;
  path: string;
  component: any;
};

export interface RouterSwitchProps {
  routes: route[];
}

export default class RouterSwitch extends Component<RouterSwitchProps, any> {
  render() {
    return (
      <Switch>
        {this.props.routes.map((route) => {
          return (
            <Route
              exact={route.exact}
              path={route.path}
              render={() => {
                if (typeof route.component.type === "function") {
                  return route.component;
                } else {
                  return <route.component key={route.path}></route.component>;
                }
              }}
            ></Route>
          );
        })}
      </Switch>
    );
  }
}
