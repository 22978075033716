export default function reducer(state = {} as any, action: any) {
	switch (action.type) {
		case "NAVIGATION_LOADING_ON": {
			return nav_loading_on(state);
		}
		case "NAVIGATION_LOADING_OFF": {
			return nav_loading_off(state);
		}
		case "ACCESS_TOKEN_LOADED": {
			state.accessTokenLoaded = true;
			return state;
		}
		case "SET_CURRENT_PAGE": {
			state.currentPageName = action.pageName;
			return state;
		}
		case "SET_USER_APPLICATION": {
			state.userApplications = action.userApplications;
			return state;
		}
		case "APPLICATION_ERROR": {
			state.applicationErrorMessage = action.message;
			return state;
		}
		case "RESET_APPLICATION_ERROR": {
			state.applicationErrorMessage = undefined;
			return state;
		}
		default:
			return state;
	}
}

function nav_loading_off(state: any) {
	state.navLoading = false;
	return state;
}
function nav_loading_on(state: any) {
	state.navLoading = true;
	return state;
}
