import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface PageManagerProps { }

export interface PageManagerState {
}

export default class PageManager extends Component< 
        PageManagerProps,
        PageManagerState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Pages">
					<TablePage isBase={false} table="Pages"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
