import React, { Component } from "react";
import { TileHolder, Tile, Form, Button, Input, List, Dialog } from "@klumpp/tools";
import SyntaxHighlighter from 'react-syntax-highlighter';
import "./DatabaseSyncer.scss";

export interface DatabaseSyncerProps { }
type Step = 0 | 1 | 2 | 3;
interface SchemaTable {
  schema: string;
  tables: string[];
}
export interface DatabaseSyncerState {
  step: Step;
  schemas: string[];
  schemaTables: SchemaTable[];
  result: any[];
  showSchema: number;
  showTable: number;
}

export default class DatabaseSyncer extends Component<
  DatabaseSyncerProps,
  DatabaseSyncerState
  > {
  state = {showSchema: -1, showTable: -1, step: 0 as Step, schemas: [], schemaTables: [] as SchemaTable[], result: [] as any};
  schemaListRef = React.createRef<List>();
  targetRef = React.createRef<Form>();
  sourceRef = React.createRef<Form>();
  lvlRef = React.createRef<Input>();
  scriptDlg = React.createRef<Dialog>();
  

  sourceDb = {host: "", pw: "", port: 0, user: "", useAppDb: false};
  targetDb = {host: "", pw: "", port: 0, user: ""};

  setSourceDB = (values: any) => {
    this.sourceDb = values;
  }
  setTargetDB = (values: any) => {
    this.targetDb = values;
  }

  start = async () => {
    if( this.sourceRef.current?.validateForm()  && this.targetRef.current?.validateForm()){
      window.backend.postData("/dbtool/checkConnection", {sourceDb: this.sourceDb, targetDb: this.targetDb}).then(async data => {
        const schemas = await window.backend.postData("/dbtool/getSchemas", this.sourceDb);
        this.setState({schemas: schemas, step: 1});

      });

    }
  }

  selectSchemas = async () => {
    const schemas = this.schemaListRef.current?.getSelected();
    const schemaTables = [];
    console.log(schemas);
    if(schemas){
      for(const schema of schemas){
        schemaTables.push({schema: schema, tables: await window.backend.postData(`/dbtool/getTables/${schema}`, this.sourceDb)});
      }
      this.setState({schemaTables: schemaTables, step: 2});

    }
  }

  calcDifferents = () => {
    const unselectedSchemas = this.schemaListRef.current?.getUnselected();
    window.backend.postData("/dbtool/differents", {sourceDb: this.sourceDb, targetDb: this.targetDb, unselectedSchemas: unselectedSchemas, level: this.lvlRef.current?.getValue().value}).then(data => {
      this.setState({result: data.result, step: 3});
    })
  }

  execute = () => {
    window.backend.postData("/dbtool/execute", {sourceDb: this.sourceDb, targetDb: this.targetDb, result: this.state.result}).then(data => {
      this.scriptDlg.current?.close();
      this.calcDifferents();
    })
  }

  render() {
    return (
      <TileHolder>
        <Dialog ref={this.scriptDlg} closeable title="SQL">
          <div className="script-dialog">
            <div className="script-dialog-content">
              <SyntaxHighlighter wrapLongLines wrapLines  showLineNumbers startingLineNumber={0} language="sql">
                {this.state.result.script ?? ""}
              </SyntaxHighlighter>
            </div>
            <div className="script-dialog-footer">
              <Button text="Cancel" type="error" onClick={() => this.scriptDlg.current?.close()}></Button>
              <Button text="Execute" type="success" onClick={this.execute}></Button>
            </div>
          </div>
        </Dialog>
        <Tile  title="1. Select source and target DB">
          <div className="db-selector">
            <div className="db-selector-forms">
              <div className="db-selector-form">
                <div className="db-selector-title">Source Db</div>
                <Form ref={this.sourceRef} onChange={this.setSourceDB}>
                  <Input formnovalidate placeholder="Host" name="host" type="text"></Input>
                  <Input formnovalidate placeholder="Port" name="port" type="number"></Input>
                  <Input formnovalidate placeholder="Username" name="user" type="text"></Input>
                  <Input formnovalidate placeholder="Password" name="pw" type="password"></Input>
                  <Input formnovalidate type="checkbox" name="useAppDb" label="Use App Db"></Input>
                </Form>
              </div>
              <div className="db-selector-form">
                <div className="db-selector-title">Target Db</div>
                <Form ref={this.targetRef} onChange={this.setTargetDB}>
                  <Input placeholder="Host" name="host" type="text"></Input>
                  <Input placeholder="Port" name="port" type="number"></Input>
                  <Input placeholder="Username" name="user" type="text"></Input>
                  <Input placeholder="Password" name="pw" type="password"></Input>
                </Form>
              </div>
            </div>
            <div className="db-selector-footer">
              <Button onClick={this.start} type="success" text="Start"></Button>
            </div>
          </div>
          
          </Tile>
          {
            this.state.step > 0 && 
            <Tile title="2. Select Schemas">
              <div className="select-schemas">
                <List unselect ref={this.schemaListRef} items={this.state.schemas}></List>
                <div className="select-schemas-footer">
                  <Button onClick={this.selectSchemas} type="success" text="Next"></Button>
                </div>
              </div>
            </Tile>
          }
          {
            this.state.step > 1 && 
            <Tile title="3. Select Tables of Schemas">
              <div className="schematables">
                <div className="schematables-items">
                {
                  this.state.schemaTables.map(schemaTable => <div className="schematables-item">
                    <div className="schematables-item-header">
                      <div className="schematables-item-header-title">{schemaTable.schema}</div>
                      <div className="schematables-item-header-anzeige">dd</div>
                    </div>
                    <List unselect items={schemaTable.tables}></List>
                    
                  </div>)
                }
                </div>
                <div className="schematables-footer">
                  <Input ref={this.lvlRef} type="select" name="level" defaultValue="ALL" options={[{label: "ALL", value: "ALL"},{label: "No drop", value: "NODROP"},{label: "No drop and modify column constraints", value: "NoDropModifyColumnConstraints"},{label: "No add column, modify or drop", value: "NoAddColumnModifyDrop"}]}></Input>
                  <Button onClick={this.calcDifferents} text="Calculate differents" type="success"></Button>
                </div>
            </div>
            </Tile>
            
          }
          {
            this.state.step > 2 &&
            <Tile type="table" title="4. Show differents">
              
              <div className="diff-shower">
                <div className="diff-shower-header"></div>
                <div className="diff-shower-content">
                  <div className="diff-shower-schemas diff-shower-item">
                    <div className="diff-shover-item-title">Schemas</div>
                    <List onSelect={indexe => {
                      if(indexe.length !== 0){
                        this.setState({showSchema: indexe[0]});
                      }
                    }} singleSelect items={this.state.result.schemas.map((schema: any) => `${schema.name} - ${schema.type}`)}></List>
                  </div>
                  <div className="diff-shower-tables diff-shower-item">
                    <div className="diff-shover-item-title">Tables</div>
                    <List onSelect={indexe => {
                      if(indexe.length !== 0){
                        this.setState({showTable: indexe[0]});
                      }
                    }}
                    singleSelect items={this.state.result.schemas[this.state.showSchema]?.tables?.map((t: any) => `${t.name} - ${t.type}`) ?? []}></List>
                  </div>

                  <div className="diff-shower-columns diff-shower-item">
                    <div className="diff-shover-item-title">Columns</div>
                    <List singleSelect items={this.state.result.schemas[this.state.showSchema]?.tables[this.state.showTable]?.columns?.columns?.map((t: any) => `${t.name} - ${t.type}`) ?? []}></List>
                  </div>
                  <div className="diff-shower-constraints diff-shower-item">
                    <div className="diff-shover-item-title">Constraints</div>
                    <List singleSelect items={this.state.result.schemas[this.state.showSchema]?.tables[this.state.showTable]?.constraints?.map((t: any) => `${t.name} - ${t.type}`) ?? []}></List>
                  </div>
                </div>
                <div className="diff-shower-footer">
                  <Button type="success" text="Show SQL Script" onClick={() => this.scriptDlg.current?.open()}></Button>
                </div>
              </div>
            </Tile>
          }
      </TileHolder>
    );
  }
}
