import { route } from "./RouterSwitch";
import { Redirect } from "react-router-dom";
import React from "react";
import UserPage from "../user/UserPage";

export const routeList: route[] = [
  {
    isBase: true,
    exact: true,
    path: "/dashboard",
    component: <Redirect to="/dashboard/base"/>
  },
  {
    isBase: true,
    exact: true,
    path: "/dashboard/base",
    component: () => "No access to an application. Please contact your administrator."
  },
  {
    isBase: true,
    exact: true,
    path: "/dashboard/personal/information",
    component: <UserPage></UserPage>
  }
];
