import React, {Component} from "react";

export interface OverviewPageItemProps {
	title: string;
	description: string;
	onClick: () => void;
}

export interface OverviewPageItemState {}

export default class OverviewPageItem extends Component<
	OverviewPageItemProps,
	OverviewPageItemState
> {
	render() {
		return (
			<div onClick={this.props.onClick} className="overview-page-item">
				<div className="overview-page-item-title">{this.props.title}</div>
				<div className="overview-page-item-description">
					{this.props.description}
				</div>
			</div>
		);
	}
}
