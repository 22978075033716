import React, { Component } from "react";
import "./Dashboard.scss";
import { withRouter, matchPath } from "react-router-dom";
import RouterSwitch, { route } from "../routes/RouterSwitch";
import { routeList } from "../routes/BaseRouteList";
import { Spinner, VerticalNavigation, VerticalNavigationGroup, VerticalNavigationItem, RouterHistory, HorizontalNavigation, HorizontalNavigationItem, IconButton } from "@klumpp/tools";
import { PageList } from "../routes/PageList";
import uniqid from "uniqid";

export interface DashboardProps { }

export interface DashboardState {
	tableHeader: any;
	tableValues: any;
	nav: navigationGroup[];
	routes: route[];
	loading: boolean;
	horizontal: horizontalItem[];
	navOpen: boolean;
	navId: string;
	message:string;
}

type horizontalItem = {
	basePath: string;
	ApplicationId: string;
	path: string;
	label: string;
	standard: boolean;
	pageId: string;
}

type navigationItem = {
	icon: string;
	path?: string;
	ApplicationId: string;
	horizontal?: horizontalItem[];
	pageId: string;
  };

type navigationGroup = {
	title?: string;
	items?: navigationItem[];
  };

class Dashboard extends Component<any, DashboardState> {
	state = {
		loading: true,
		tableHeader: [],
		tableValues: [],
		nav: [],
		routes: [],
		horizontal: [],
		navOpen: false,
		navId: uniqid(),
		message: ""
	};

	historyListener:() => void = () => {};

	loadNavigation = () => {
		window.backend.getData(
			"/base/getNavigation", true).then(
				(data) => {
					let groups: navigationGroup[] = data;
					let routes: route[] = [];
					for (let group of groups) {
						if (group.items) {
							for (let item of group.items) {

								if (item.path && item.pageId && PageList[item.pageId]) {
									routes.push({path: item.path, exact: true, isBase: false, component: PageList[item.pageId]});
									
								}

								if(matchPath(window.location.pathname, { exact: true, path: item.path })){
									window.backend.setAppId(item.ApplicationId);
								}

								if(item.horizontal){
									for(const hItem of item.horizontal){
										if(matchPath(window.location.pathname, { exact: true, path: hItem.basePath + hItem.path })){
											window.backend.setAppId(item.ApplicationId);
										}

										if(hItem.path && hItem.basePath && hItem.pageId && PageList[hItem.pageId]){
											routes.push({path: hItem.basePath + hItem.path, exact: true, isBase: false, component: PageList[hItem.pageId]});
										}
									}
								}
							}
						}
					}

					routes = routes.concat(routeList);

					this.setState({ nav: groups, routes: routes, loading: false, message: groups.length === 0?this.state.message:"" }, () => {
						routes.forEach(route => {
							if(window.location.pathname.includes(route.path)){
								this.setHorizontal(window.location.pathname);
							}							
						});
						
						if(window.location.pathname === "/dashboard/base"){
							for(const g of groups){
								if(g.items && g.items.length !== 0 && g.items[0].path){
									window.backend.setAppId(g.items[0].ApplicationId);
									RouterHistory.replace(g.items[0].path);
									break;
								}
							}
						}
						
					});
				}
			).catch(err => {
				if(err.error){
					this.setState({message: err.error.data, loading: false});
				}
			});
	};

	setHorizontal = (pathname: string) => {
		this.state.nav.forEach((group: navigationGroup) => {
			group.items?.forEach(item => {

				if(item.horizontal && item.path && pathname.includes(item.path)){
					
					this.setState({horizontal: item.horizontal}, () => {
						if(!item.horizontal?.find(hori => hori.basePath + hori.path === pathname)){
							const standard = item.horizontal?.find(hori => hori.standard);
							if(standard){
								RouterHistory.replace(standard.basePath + standard.path);
							}
						}
					});
				}
			
			})
		});
	}

	componentDidMount() {
		this.loadNavigation();
		this.historyListener = RouterHistory.listen((update: any) => {
			this.setHorizontal(update.pathname);
		});
	}

	componentWillUnmount(){
		this.historyListener();
	}

	navlistener = (event: any) => {
        let target = event.target;
        const elm = document.querySelector(`#${this.state.navId}`);

        do{

            if(target === elm){
                
                return;
            }
            target = target.parentNode;
        }while(target);
        this.toggleNav();
    }

	toggleNav = () => {
		this.setState({navOpen: !this.state.navOpen}, () => {
			if(this.state.navOpen){
				document.addEventListener("click", this.navlistener);
			}else{
				document.removeEventListener("click", this.navlistener);
			}
		});

	}

	getClasses = () => {
		const classes = ["dashboard"];
		if(this.state.nav.length === 0){
			classes.push("without-vert");
		}

		return classes.join(" ");
	}

	render() {
		return this.state.loading ? (
			<Spinner></Spinner>
		) : (
				<div className={this.getClasses()}>
					<div className="dashboard-horizontal-nav">
						<div className="dashboard-vertical-nav-btn">
							<IconButton onClick={this.toggleNav} type="transparent" icon="menu"></IconButton>
						</div>
						<HorizontalNavigation title="Dashboard" >
							{
								this.state.horizontal.map((item: horizontalItem) => {
								return <HorizontalNavigationItem history={RouterHistory} to={`${item.basePath}${item.path}`}>{item.label}</HorizontalNavigationItem>
								})
							}
						</HorizontalNavigation>
					</div>
					
					<div className="dashboard-vertical-nav-placeholder"></div>
					<div id={this.state.navId} className={`dashboard-vertical-nav${this.state.navOpen?" nav-open":""}`}>
						<VerticalNavigation>
							<VerticalNavigationItem position="BOTTOM" icon="account_circle" to="/dashboard/personal/information" history={RouterHistory}/>
							<VerticalNavigationItem position="BOTTOM" icon="logout" to="/logout" history={RouterHistory}/>
							{this.state.nav.map((navItem: any, index) =>
								<VerticalNavigationGroup key={index} title={navItem.title}>
									{navItem.items.map((item: any, index: number) => <VerticalNavigationItem key={index} icon={item.icon} to={item.path} history={RouterHistory} onClick={() => {
										window.backend.setAppId(item.ApplicationId);
									}} />)}
								</VerticalNavigationGroup>
							)}
						</VerticalNavigation>
					</div>
					
					<div className="dashboard-content">
						{!this.state.message && 
							<RouterSwitch routes={this.state.routes}></RouterSwitch>
						}
					</div>
				</div>
			);
	}
}

export default withRouter(Dashboard);
