import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface HorizontalNavigationManagerProps { }

export interface HorizontalNavigationManagerState {

}

export default class NavigationManager extends Component<
        HorizontalNavigationManagerProps,
        HorizontalNavigationManagerState
	> {


	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Navigation">
                    <TablePage isBase={false} order={["ApplicationId", "BasePath", "order"]} table="HorizontalNavigationItems"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
