import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface PermissionManagerProps { }

export interface PermissionManagerState {

}

export default class PermissionManager extends Component<
	PermissionManagerProps,
	PermissionManagerState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Permissions">
					<TablePage isBase={false} table="Permissions"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
