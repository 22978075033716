import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface TablePostprocessManagerProps { }
export interface TablePostprocessManagerState {

}

export default class TablePostprocessManager extends Component<
  TablePostprocessManagerProps,
  TablePostprocessManagerState
  > {

  render() {
    return (
      <TileHolder>
        <Tile type="table" title="Table Header Management">
          <TablePage isBase={false} table="TablePostprocess"></TablePage>
          </Tile>
      </TileHolder>
    );
  }
}
