import React, { Component } from "react";
import OverviewPageItem from "./OverviewPageItem";
import { RouterHistory } from "@klumpp/tools";
import "./OverviewPage.scss";

export interface OverviewPageProps { }

export interface OverviewPageState { }

export default class OverviewPage extends Component<
	OverviewPageProps,
	OverviewPageState
	> {
	render() {
		return (
			<div className="overview-page">
				<div className="overview-page-title">Übersicht</div>
				<div className="overview-page-content">
					<OverviewPageItem
						title="Dashboard"
						description="Über das Admin Dashboard können verschiedene Applikationen und Dienste verwaltet werden."
						onClick={() => RouterHistory.push("/dashboard")}
					></OverviewPageItem>
					<OverviewPageItem
						title="Cloud"
						description="Offizielle Cloud der Familie Klumpp. Hier können Dateien langfristig abgelegt oder sogar veröffentlicht werden."
						onClick={() =>
							(window.location.href =
								"http://pi.blickleklumppwiens.de:81/owncloud")
						}
					></OverviewPageItem>
				</div>
			</div>
		);
	}
}
