import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface RouteManagerProps { }

export interface RouteManagerState {
}

export default class RouteManager extends Component<
	RouteManagerProps,
	RouteManagerState
	> {
	
	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Routes">
					<TablePage isBase={false} table="Routes"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
