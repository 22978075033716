import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface AppUserManagerProps { }
export interface AppUserManagerState {

}

export default class AppUserManager extends Component<
  AppUserManagerProps,
  AppUserManagerState
  > {

  render() {
    return (
      <TileHolder>
        <Tile type="table" title="Application User Management">
          <TablePage isBase={false} table="Users" getUrl="/users" postUrl="/users" deleteUrl="/users"></TablePage>
          </Tile>
      </TileHolder>
    );
  }
}
