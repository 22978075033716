import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface UserManagerProps { }
export interface UserManagerState {
}

export default class UserManager extends Component<
  UserManagerProps,
  UserManagerState
  > {

  render() {
    return (
      <TileHolder>
        <Tile type="table" title="User Management">
          <TablePage getUrl="/base/users/get" postUrl="/base/users/save" deleteUrl="/base/users/delete" table="Users"></TablePage>
        </Tile>
        <Tile type="table" title="User Permission Management">
          <TablePage isBase={false} table="UserPermissions"></TablePage>
        </Tile>
      </TileHolder>
    );
  }
}
