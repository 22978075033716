import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface ApplicationManagerProps { }

export interface ApplicationManagerState {

}

export default class ApplicationManager extends Component<
	ApplicationManagerProps,
	ApplicationManagerState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Application">
					<TablePage isBase={false} table="Applications"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
