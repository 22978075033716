import { Spinner } from "@klumpp/tools";
import {
  Tile,
  TileHolder,
  FormPager,
  Form,
  Input,
  CustomFormPage,
} from "@klumpp/tools";
import React, { Component } from "react";
import "./RequestDriveFormular.scss";

export interface RequestDriveFormularProps {}

export interface RequestDriveFormularState {
  loading: boolean;
  institutions: any[];
}

export default class RequestDriveFormular extends Component<
  RequestDriveFormularProps,
  RequestDriveFormularState
> {
  state = { institutions: [], loading: true };
  onSubmit = (values: any) => {
    window.backend.postData("/asb/driveservice/drives", values);
  };

  componentDidMount() {
    window.backend.getData("/asb/driveservice/institutions").then((data) => {
      this.setState({ institutions: data, loading: false });
    });
  }

  render() {
    if (this.state.loading) {
      return <Spinner></Spinner>;
    }
    return (
      <TileHolder>
        <Tile title="Request drive" type="form">
          <FormPager onSubmit={this.onSubmit}>
            <CustomFormPage title="General Information">
              <div className="request-derive-general">
                <Form title="General">
                  <Input
                    maxWidth="300px"
                    type="searchselect"
                    name="institutionId"
                    label="Institution"
                    options={this.state.institutions}
                  ></Input>
                  <Input
                    name="transportationDate"
                    type="date"
                    label="Transportation Date"
                  ></Input>
                  <Input
                    name="transportationTime"
                    type="time"
                    label="Transportation Time"
                  ></Input>
                </Form>
                <Form title="Caller information">
                  <Input
                    name="callerFirstname"
                    type="text"
                    label="Firstname"
                  ></Input>
                  <Input
                    name="callerLastname"
                    type="text"
                    label="Lastname"
                  ></Input>
                  <Input
                    name="callerTelephone"
                    type="tel"
                    label="Telephone"
                  ></Input>
                </Form>
              </div>
            </CustomFormPage>
            <CustomFormPage title="Pickup information">
              <div className="request-drive-pickup">
                <div className="request-drive-pickup-location">
                  <Form title="Location">
                    <Input
                      name="pickupCity"
                      type="text"
                      maxWidth="200px"
                      label="City"
                    ></Input>
                    <Input
                      maxWidth="100px"
                      name="pickupPostcode"
                      type="text"
                      label="Postcode"
                    ></Input>
                    <Input
                      name="pickupStreet"
                      type="text"
                      label="Street"
                    ></Input>
                    <Input
                      name="pickupStation"
                      type="text"
                      label="Station"
                    ></Input>
                  </Form>
                </div>
                <div className="request-drive-pickup-additional">
                  <Form title="Additional information">
                    <Input
                      name="wheelchair"
                      type="checkbox"
                      label="Wheelchair?"
                      formnovalidate
                    ></Input>
                    <Input
                      name="electroWheelchair"
                      type="checkbox"
                      label="Electrowheelchair?"
                      formnovalidate
                    ></Input>
                    <Input
                      name="rollator"
                      type="checkbox"
                      label="Rollator?"
                      formnovalidate
                    ></Input>
                    <Input
                      name="carryingChair"
                      type="checkbox"
                      label="Carrying chair?"
                      formnovalidate
                    ></Input>
                    <Input
                      name="ownWheelchair"
                      type="checkbox"
                      label="Own wheelchair?"
                      formnovalidate
                    ></Input>
                    <Input
                      name="ambulatory"
                      type="checkbox"
                      label="Ambulatory?"
                      formnovalidate
                    ></Input>
                    <Input
                      name="lying"
                      type="checkbox"
                      label="Lying?"
                      formnovalidate
                    ></Input>
                  </Form>
                </div>
              </div>
            </CustomFormPage>
            <Form title="Patient information">
              <Input name="patientLastname" type="text" label="Surname"></Input>
              <Input name="patientPrename" type="text" label="Prename"></Input>
              <Input
                name="patientCity"
                type="text"
                maxWidth="200px"
                label="City"
              ></Input>
              <Input
                maxWidth="100px"
                name="patientPostcode"
                type="text"
                label="Postcode"
              ></Input>
              <Input name="patientStreet" type="text" label="Street"></Input>
            </Form>
            <Form title="Target information">
              <Input
                name="targetCity"
                type="text"
                label="City"
                maxWidth="200px"
              ></Input>
              <Input
                name="targetPostcode"
                type="text"
                label="Postcode"
                maxWidth="100px"
              ></Input>
              <Input name="targetStreet" type="text" label="Street"></Input>
              <Input name="targetStation" type="text" label="Station"></Input>
            </Form>
          </FormPager>
        </Tile>
      </TileHolder>
    );
  }
}
