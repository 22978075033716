import React, { Component } from "react";
import { Button } from "@klumpp/tools";
import "./Error.scss";

export interface ErrorProps {}

export interface ErrorState {}

export default class Error extends Component<ErrorProps, ErrorState> {
  state = {};
  render() {
    return (
      <div className="backend-error">
        <h1>Backend Error</h1>
        <p>An error occurred, while you tried to access this site.</p>
        <p>If this error persists, please contact the Administrator.</p>
        <Button
          onClick={() => {
            window.routerHistory.goBack();
          }}
          text="Try again"
        ></Button>
      </div>
    );
  }
}
