import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface TablePermissionManagerProps { }

export interface TablePermissionManagerState {

}

export default class TablePermissionManager extends Component<
	TablePermissionManagerProps,
	TablePermissionManagerState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Table Permissions">
					<TablePage isBase={false} table="TablePermissions"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
