import { TileHolder } from "@klumpp/tools";
import { Form } from "@klumpp/tools";
import { showNotification } from "@klumpp/tools";
import { Input } from "@klumpp/tools";
import { Tile } from "@klumpp/tools";
import React, { Component } from "react";
import "./UserPage.scss";

export interface UserPageProps{}

type Status = "NEUTRAL" | "VALID" | "INVALID";

export interface UserPageState{
    user: any;
    minimumLength: Status;
    minimumUpperCase: Status;
    minimumLowserCase: Status;
    minimumSpecial: Status;
    minimumNumber: Status;
    samePassword: Status;
}

export default class UserPage extends Component<UserPageProps, UserPageState>{
    state = {user: {} as any, samePassword: "NEUTRAL" as Status,  minimumNumber: "NEUTRAL" as Status,  minimumLength: "NEUTRAL" as Status, minimumUpperCase: "NEUTRAL" as Status, minimumLowserCase: "NEUTRAL" as Status, minimumSpecial: "NEUTRAL" as Status}
    componentDidMount(){
        window.auth.getUser().then(user => {
            console.log(user);
            this.setState({user: user});
        })
    }

    onChange = (values: any) => {
        const newPassword = values.newPassword;
        const oldPassword = values.oldPassword;
        const wdhPassword = values.newWdhPassword;

        if(!newPassword && !oldPassword  && !wdhPassword){
            this.setState({minimumLength: "NEUTRAL", minimumSpecial: "NEUTRAL", minimumUpperCase: "NEUTRAL", minimumLowserCase: "NEUTRAL", minimumNumber: "NEUTRAL", samePassword: "NEUTRAL"});
            return;
        }

        if(newPassword !== ""){
            if(newPassword.length < 8){
                this.setState({minimumLength: "INVALID"});
            }else{
                this.setState({minimumLength: "VALID"});
            }

            if(newPassword.match(/[a-z]/)){
                this.setState({minimumLowserCase: "VALID"});
            }else{
                this.setState({minimumLowserCase: "INVALID"});
            }

            if(newPassword.match(/[A-Z]/)){
                this.setState({minimumUpperCase: "VALID"});
            }else{
                this.setState({minimumUpperCase: "INVALID"});
            }

            if(newPassword.match(/[0-9]/)){
                this.setState({minimumNumber: "VALID"});
            }else{
                this.setState({minimumNumber: "INVALID"});
            }
            //[$&+,:;=?@#|'<>.-^*()%!]
            if(newPassword.match(/[$&+,:;=?@#|'<>.\-^*()%!]/)){
                this.setState({minimumSpecial: "VALID"});
            }else{
                this.setState({minimumSpecial: "INVALID"});
            }
        }

        if(newPassword !== "" && wdhPassword !== ""){
            if(newPassword === wdhPassword){
                this.setState({samePassword: "VALID"});
            }else{
                this.setState({samePassword: "INVALID"});
            }
        }
    }

    onSubmit = async (values: any, reset: any) => {
        
        if(this.state.samePassword === "VALID" && this.state.minimumLength === "VALID" && this.state.minimumLowserCase === "VALID" && this.state.minimumNumber === "VALID" && this.state.minimumSpecial === "VALID" && this.state.minimumUpperCase === "VALID"){
            if(values.oldPassword !== "" && values.newPassword !== "" && values.newWdhPassword !== ""){
                if(values.oldPassword !== values.newPassword){
                    await window.backend.postData("/base/users", values, true);
                    reset();
                }else{
                    showNotification({msg: "Your new password cannot be your old password!", type: "E"});
                }
            }else{
                showNotification({msg: "Please fill all fields!", type: "E"});
            }
        }else{
            showNotification({msg: "Please check the password requirements!", type: "E"});
        }
    }

    render(){
        return  <TileHolder>
                <Tile minWidth="435px" title="Personal information">

                    <div className="personal-information">
                        <div className="personal-profile-picture">
                            <img alt="Profile" src={this.state.user.picture}></img>
                        </div>
                        <div className="personal-profile-infos">
                            <div className="personal-profile-infos-name">
                                <div className="personal-profile-infos-name-fullname">
                                    {
                                        `${this.state.user.given_name} ${this.state.user.family_name}`
                                    }
                                </div>
                                <div className="personal-profile-infos-name-name">{this.state.user.name}</div>
                            </div>
                            
                            <div className="personal-profiles-infos-additional">
                                <div className="personal-profiles-infos-additional-item">
                                    <div className="personal-profiles-infos-additional-item-label">{"Benutzername:"}</div>
                                <div className="personal-profiles-infos-additional-item-value">{this.state.user.nickname}</div>
                                </div>
                                <div className="personal-profiles-infos-additional-item">
                                    <div className="personal-profiles-infos-additional-item-label">{"E-Mail:"}</div>
                                <div className="personal-profiles-infos-additional-item-value">{this.state.user.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Tile>
                <Tile title="Change password" minWidth="450px">
                    <div className="change-password">
                        <div className="change-password-fields">
                            <Form onChange={this.onChange} onSubmit={this.onSubmit}>
                                <Input type="password" name="oldPassword" formnovalidate label="Old password"></Input>
                                <Input type="password" name="newPassword" formnovalidate label="New password"></Input>
                                <Input type="password" name="newWdhPassword" formnovalidate label="Repeat new password"></Input>
                                <Input type="button" cType="SUBMIT" name=""></Input>
                                <Input type="button" cType="CANCEL" name=""></Input>
                            </Form>
                        </div>
                        <div className="change-password-validations">
                            <div className={`change-password-validations-item ${this.state.minimumLength}`}>Minimum length of 8 characters.</div>
                            <div className={`change-password-validations-item ${this.state.minimumNumber}`}>Minimum one number.</div>
                            <div className={`change-password-validations-item ${this.state.minimumLowserCase}`}>Minimum one lowercase letter.</div>
                            <div className={`change-password-validations-item ${this.state.minimumUpperCase}`}>Minimum one uppercase letter.</div>
                            <div className={`change-password-validations-item ${this.state.minimumSpecial}`}>Minimum one special character.</div>
                            <div className={`change-password-validations-item ${this.state.samePassword}`}>Password and repetition has to be the same.</div>
                        </div>
                    </div>
                </Tile>
                <Tile title="Data privacy settings">

                </Tile>
            </TileHolder>
    }
}