import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface Auth0RoleManagerProps { }

export interface Auth0RoleManagerState {
}

export default class Auth0RoleManager extends Component<
	Auth0RoleManagerProps,
	Auth0RoleManagerState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Roles">
					<TablePage isBase={true} getUrl={"/base/auth0/roles/get"} postUrl={"/base/auth0/roles/save"}  deleteUrl={"/base/auth0/roles/delete"} table="Auth0Roles"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
