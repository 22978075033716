import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface VerticalNavigationManagerProps { }

export interface VerticalNavigationManagerState {
	navigationData: any;
	hNavigationData: any;
	navigationColumns: any[];
	hNavigationColumns: any[];
}

export default class VerticalNavigationManager extends Component<
	VerticalNavigationManagerProps,
	VerticalNavigationManagerState
	> {
	

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Navigation">
					<TablePage isBase={false} table="NavigationItems" order={["ApplicationId", "order"]}></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
