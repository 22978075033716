import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../../table/TablePage";

export interface InstitutionsProps { }

export interface InstitutionsState {

}

export default class Institutions extends Component<
	InstitutionsProps,
	InstitutionsState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Institutions">
					<TablePage isBase={false} table="Institutions"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
