import React, { Component } from "react";
import { TileHolder, Tile, Form, Input } from "@klumpp/tools";

export default class BasePage extends Component<any, any> {
  holderRef = React.createRef<TileHolder>();

  componentDidMount() {
    setTimeout(() => {
      this.holderRef.current && this.holderRef.current.loading(false);
    }, 5000);
    window.backend.getData("/something").then((data) => { });
  }
  render() {
    return (
      <TileHolder ref={this.holderRef}>
        <Tile>
          <Form
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            <Input type="checkbox" name="boolean"></Input>
            <Input
              type="button"
              cType="CANCEL"
              placeholder="Cancel"
              name="cancel"
            ></Input>
            <Input type="number" name="number"></Input>
            <Input type="password" name="password"></Input>
            <Input
              type="button"
              cType="SUBMIT"
              placeholder="Submit"
              name="submit"
            ></Input>
            <Input type="text" name="text"></Input>
          </Form>
        </Tile>
      </TileHolder>
    );
  }
}
