import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { RouterHistory as history } from "@klumpp/tools";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router history={history} basename={"/"}>
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    ></link>
    <App history={history} />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
