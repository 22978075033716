import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface CustomersManagerProps { }

export interface CustomersManagerState {

}

export default class CustomersManager extends Component<
    CustomersManagerProps,
    CustomersManagerState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Customers">
					<TablePage isBase={false} table="Customers"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
