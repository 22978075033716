import React, { Component } from "react";
import { TileHolder, Tile, Spinner } from "@klumpp/tools";
import TablePage from "../../../table/TablePage";

export interface DrivesManagerProps {}

export interface DrivesManagerState {
  institutionId: string;
  institutions: any[];
  loading: boolean;
}

export default class DrivesManager extends Component<
  DrivesManagerProps,
  DrivesManagerState
> {
  state = { institutionId: "", institutions: [], loading: true };
  tableRef = React.createRef<TablePage>();

  componentDidMount() {
    window.backend.getData("/asb/driveservice/institutions").then((data) => {
      this.setState({ institutions: data, loading: false });
    });
  }
  render() {
    if (this.state.loading) {
      return (
        <TileHolder>
          <Spinner></Spinner>
        </TileHolder>
      );
    }

    return (
      <TileHolder
        onSelectionChange={(data) => {
          this.setState({ institutionId: data.institutionId }, () => {
            //this.tableRef.current?.loadTable();
          });
        }}
        selector={[
          {
            name: "institutionId",
            type: "searchselect",
            label: "Bitte wählen sie eine Institution aus",
            options: this.state.institutions,
          },
        ]}
      >
        {this.state.institutionId && (
          <Tile type="table" title="Manage Drives">
            <TablePage
              ref={this.tableRef}
              isBase={false}
              table="Drives"
              getUrl={`/asb/driveservice/drives/${this.state.institutionId}`}
              postUrl="/asb/driveservice/drives"
              deleteUrl="/asb/driveservice/drives"
            ></TablePage>
          </Tile>
        )}
      </TileHolder>
    );
  }
}
