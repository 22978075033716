import React, { Component } from "react";
import { TileHolder, Tile } from "@klumpp/tools";
import TablePage from "../../table/TablePage";

export interface RoleManagerProps { }

export interface RoleManagerState {

}

export default class RoleManager extends Component<
	RoleManagerProps,
	RoleManagerState
	> {

	render() {
		return (
			<TileHolder>
				<Tile type="table" title="Manage Roles">
					<TablePage isBase={false} table="Roles"></TablePage>
				</Tile>
				<Tile type="table" title="Manage Role Permissions">
					<TablePage isBase={false} table="RolePermissions"></TablePage>
				</Tile>
			</TileHolder>
		);
	}
}
